<template>
  <div class="container pt-5">
    <p>
      {{ $t("ParComit1") }}
    </p>
    <p>
      {{ $t("ParComit2") }}
    </p>

    <p>
      {{ $t("ParComit3") }}
    </p>
    <p>
      {{ $t("ParComit4") }}
    </p>
    <p>
      {{ $t("ParComit5") }}
    </p>
    <p>
      Мақсат Оспанов <a href="maksat.ospanov@jihc.kz">maksat.ospanov@jihc.kz</a>
    </p>
    <p>Эмель Картав <a href="emel.kartav@jihc.kz">emel.kartav@jihc.kz</a></p>
  </div>
</template>

<script>
export default {
  name: "Jumbotron2",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
