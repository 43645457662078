<script>
import Hero2 from "../components/Hero2.vue";

export default {
  name: "App",
  components: {
    Hero2,
  },
};
</script>
<template>
  <hero2 />
</template>
