<template>
  <div class="container my-5">
    <div class="container text-center">
      
    </div>
  </div>
</template>

<script>
export default {
  name: "Column",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
