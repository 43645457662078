<script>
import admin from "@/components/admin.vue";
export default {
  name: "App",
  components: {
    admin,
  },
};
</script>
<template>
  <admin />
</template>
