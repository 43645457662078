<template>
  <div class="container text-center mt-5">
    <div class="row mb-2">
      <div class="col-6 p-2">
        <img
          src="../assets/football/football1.avif"
          class="img-fluid"
          alt="First image description"
        />
      </div>
      <div class="col-6 p-2">
        <img
          src="../assets/football/football2.avif"
          class="img-fluid"
          alt="Second image description"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.image1 {
  background-image: url("../assets/jihc/спортзал/sport1.avif");
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
}

.image2 {
  background-image: url("../assets/jihc/спортзал/sport1.avif");
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
}
.image3 {
  background-image: url("../assets/jihc/спортзал/sport1.avif");
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
}
.image4 {
  background-image: url("../assets/jihc/спортзал/sport1.avif");
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
}
.image5 {
  background-image: url("../assets/jihc/спортзал/sport1.avif");
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
