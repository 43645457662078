<script>
import login from "../components/login.vue";

export default {
  name: "App",
  components: {
    login,
  },
};
</script>
<template>
  <login></login>
</template>
