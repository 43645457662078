<script>
import Hero2 from "../components/Hero2.vue";
import PreperatoinProf from "../components/PreperatoinProf.vue";

export default {
  components: {
    Hero2,
    PreperatoinProf,
  },
};
</script>
<template>
  <Hero2 title="Проф. ориентация" podtitle="Главная / Проф. ориентация" />
  <PreperatoinProf />
</template>
