<script>
import Hero2 from "../components/Hero2.vue";
import Pedogogika from "../components/Pedogogika.vue";

export default {
  components: {
    Hero2,
    Pedogogika,
  },
};
</script>
<template>
  <Hero2
    title="Педагогика-математика"
    podtitle="Главная / Педагогика-математика"
  />
  <Pedogogika />
</template>
