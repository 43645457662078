<script>
import Hero2 from "../components/Hero2.vue";
import Sporthall from "../components/Sporthall.vue";

export default {
  components: {
    Hero2,
    Sporthall,
  },
};
</script>
<template>
  <hero2 title="Спортзал" podtitle="Главная / Спортзал" />
  <Sporthall />
</template>
