<template>
  <div class="container pt-5">
    <p>
      {{ $t("ConTeacherP1") }}
    </p>

    <p>
      {{ $t("ConTeacherP2") }}
    </p>
    <p>
      {{ $t("ConTeacherP3") }}
    </p>
    <p> {{ $t("ConTeacherP4") }}</p>
  </div>
</template>

<script>
export default {
  name: "Jumbotron2",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
