<template>
  <div class="container mb-4 rounded-3">
    <div class="container-fluid py-5">
      <h1 class="display-5 fw-bold mb-5">
        {{ $t("TranslateH1") }}
      </h1>
      <p class="mb-3">
        {{ $t("TranslateP1") }}
      </p>
      <p class="mb-3">
        {{ $t("TranslateP2") }}
      </p>
      <p class="mb-3">
        {{ $t("TranslateP3") }}
      </p>
      <p class="mb-3">
        {{ $t("TranslateP4") }}
      </p>
      <p class="mb-3">
        {{ $t("TranslateP5") }}
      </p>
      <p class="mb-3">
        {{ $t("TranslateP6") }}
      </p>
      <p class="mb-3">
        {{ $t("TranslateP7") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
