<script>
import Hero2 from "../components/Hero2.vue";
import AllNews from "../components/AllNews.vue";

export default {
  components: {
    Hero2,
    AllNews,
  },
};
</script>
<template>
  <Hero2 title="Объявления" podtitle="Главная / Объявления" />
  <AllNews />
</template>
