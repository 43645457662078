<template>
  <div class="container pt-5">
    <h5 class="fw-bold">{{ $t("ComitH1") }}</h5>
    <p>
    {{ $t("ComitP1") }}
    </p>
    <h5 class="fw-bold">{{ $t("ComitH2") }}</h5>
    <p>
      {{ $t("ComitP2") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Jumbotron2",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
