<script>
import Hero2 from "../components/Hero2.vue";
import barbe from "../components/Barbe.vue";

export default {
  components: {
    Hero2,
    barbe,
  },
};
</script>
<template>
  <Hero2 title="Беседка для барбекю" podtitle="Главная / Беседка для барбекю" />
  <barbe />
</template>
