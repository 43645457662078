<script>
import NotFound from "../components/NotFound.vue";
export default {
  name: "App",
  components: {
    NotFound,
  },
};
</script>
<template>
  <NotFound />
</template>
