<template>
  <section id="info " class="my-5 container">
    <div class="row">
      <div
        class="col-lg-6 col-md-6 col-sm-6 justify-content-center text-left infoS"
      >
        <p class="">
          Dias
        </p>
        <p>Ссылка на электронный журнал:</p>
        <a href="#">https://login1.edupage.org/</a>
      </div>

      <img
        src="../assets/Director.png"
        alt=""
        class="col-lg-6 col-md-6 col-sm-6 align-self-center"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "Jumbotron2",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
