<script>
import Hero2 from "../components/Hero2.vue";
import football from "../components/football.vue";

export default {
  components: {
    Hero2,
    football,
  },
};
</script>
<template>
  <Hero2 title="Футбольное поле" podtitle="Главная / Футбольное поле" />
  <football />
</template>
