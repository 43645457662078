<script>
import Hero2 from "../components/Hero2.vue";
import careerguid from "../components/careerguid.vue";

export default {
  name: "App",
  components: {
    Hero2,
    careerguid,
  },
};
</script>
<template>
  <hero2 title="Проф. ориентация" podtitle="Главная / Проф. ориентация" />
  <careerguid />
</template>
