<template>
  <div class="container pt-5">
    <p>
      {{ $t("ContactP1") }}
    </p>
    <ol class="list-group list-group list-group-numbered">
      <li class="list-group-item border-0">
        {{ $t("ContactP2") }}
      </li>
      <li class="list-group-item border-0">
        {{ $t("ContactP3") }}
      </li>
      <li class="list-group-item border-0">
        {{ $t("ContactP4") }}
      </li>
      <li class="list-group-item border-0">
        {{ $t("ContactP5") }}
      </li>
      <li class="list-group-item border-0">
        {{ $t("ContactP6") }}
      </li>
      <li class="list-group-item border-0">
        {{ $t("ContactP7") }}
      </li>
      <li class="list-group-item border-0">
        {{ $t("ContactP8") }}
      </li>
      <li class="list-group-item border-0">
        {{ $t("ContactP9") }}
      </li>
      <li class="list-group-item border-0">
        {{ $t("ContactP10") }}
      </li>
    </ol>

    <p>
      {{ $t("ContactP11") }}
    </p>
    <p>
      Мақсат Оспанов <a href="maksat.ospanov@jihc.kz">maksat.ospanov@jihc.kz</a>
    </p>
    <p>Эмель Картав <a href="emel.kartav@jihc.kz">emel.kartav@jihc.kz</a></p>
  </div>
</template>

<script>
export default {
  name: "Jumbotron2",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
