<template>
  <div class="container my-5 justify-content-center">
    <div class="text-center">
      <div class="row align-self-center gap-5 px-5">
        <div
          class="col d-flex flex-row gap-3 text-center justify-content-center p-4 rounded-4 py-3 align-items-center shadow"
        >
          <i class="bi"><img src="../assets/factsCard1.png" alt="" /></i>
          <h2>{{ $t("Fact1H") }}</h2>

          <p class="f m-0 fw-normal">
            {{ $t("Fact1P") }}
          </p>
        </div>
        <div
          class="col d-flex flex-row gap-3 text-center justify-content-center p-4 rounded-4 py-3 align-items-center shadow"
        >
          <i class="bi"><img src="../assets/factsCard2.png" alt="" /></i>
          <h2>{{ $t("Fact2H") }}</h2>
          <p class="f m-0 fw-normal">{{ $t("Fact2P") }}</p>
        </div>
        <div
          class="col d-flex flex-row gap-3 text-center justify-content-center p-4 rounded-4 py-3 align-items-center shadow"
        >
          <i class="bi"><img src="../assets/factsCard3.png" alt="" /></i>
          <h2>{{ $t("Fact3H") }}</h2>
          <p class="f m-0 fw-normal">{{ $t("Fact3P") }}</p>
        </div>
        <div
          class="col d-flex flex-row gap-3 text-center justify-content-center p-4 rounded-4 py-3 align-items-center shadow"
        >
          <i class="bi"><img src="../assets/factsCard4.png" alt="" /></i>
          <h2>{{ $t("Fact4H") }}</h2>
          <p class="f m-0 fw-normal">
            {{ $t("Fact4P") }}
          </p>
        </div>
        <div
          class="col d-flex flex-row gap-3 text-center justify-content-center p-4 rounded-4 py-3 align-items-center shadow"
        >
          <i class="bi"><img src="../assets/factsCard5.png" alt="" /></i>
          <h2>{{ $t("Fact5H") }}</h2>
          <p class="f m-0 fw-normal">
            {{ $t("Fact5P") }}
          </p>
        </div>

        <div
          class="col d-flex flex-row gap-3 text-center justify-content-center p-4 rounded-4 py-3 align-items-center shadow"
        >
          <i class="bi"><img src="../assets/factsCard7.png" alt="" /></i>
          <h2>{{ $t("Fact7H") }}</h2>
          <p class="f m-0 fw-normal">{{ $t("Fact7P") }}</p>
        </div>
        <div
          class="col d-flex flex-row gap-3 text-center justify-content-center p-4 rounded-4 py-3 align-items-center shadow"
        >
          <i class="bi"><img src="../assets/factsCard8.png" alt="" /></i>
          <h2>{{ $t("Fact8H") }}</h2>
          <p class="f m-0 fw-normal">
            {{ $t("Fact8P") }}
          </p>
        </div>
        <div
          class="col d-flex flex-row gap-3 text-center justify-content-center p-4 rounded-4 py-3 align-items-center shadow"
        >
          <i class="bi"><img src="../assets/factsCard9.png" alt="" /></i>
          <h2>{{ $t("Fact9H") }}</h2>
          <p class="f m-0 fw-normal">{{ $t("Fact9P") }}</p>
        </div>
        <div
          class="col d-flex flex-row gap-3 text-center justify-content-center p-4 rounded-4 py-3 align-items-center shadow"
        >
          <i class="bi"><img src="../assets/factsCard10.png" alt="" /></i>
          <h2>{{ $t("Fact10H") }}</h2>
          <p class="f m-0 fw-normal">{{ $t("Fact10P") }}</p>
        </div>
        <div
          class="col d-flex flex-row gap-3 text-center justify-content-center p-4 rounded-4 py-3 align-items-center shadow"
        >
          <i class="bi"><img src="../assets/factsCard11.png" alt="" /></i>
          <h2>{{ $t("Fact11H") }}</h2>
          <p class="f m-0 fw-normal">
            {{ $t("Fact11P") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
