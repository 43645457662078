<template>
  <div class="container pt-5">
    <p class="fw-bold fs-3">
      {{ $t("CareerH1") }}
    </p>
    <p>
      {{ $t("CareerP1") }}
    </p>

    <h5 class="fw-bold">{{ $t("CareerH2") }}</h5>
    <p>
      {{ $t("CareerP2") }}
    </p>
    <h5 class="fw-bold">{{ $t("CareerH3") }}</h5>
    <p>
      {{ $t("CareerP3") }}
    </p>
    <h5 class="fw-bold">{{ $t("CareerH4") }}</h5>
    <p>
      {{ $t("CareerP4") }}
    </p>
    <p>  {{ $t("CareerP5") }}</p>
    <a href="gaziz.janayev@jihc.kz">gaziz.janayev@jihc.kz</a>
  </div>
</template>

<script>
export default {
  name: "Jumbotron2",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
