<script>
import Hero2 from "../components/Hero2.vue";
import prachka from "../components/Prachka.vue";

export default {
  components: {
    Hero2,
    prachka,
  },
};
</script>
<template>
  <Hero2 title="Прачечная" podtitle="Главная / Прачечная" />
  <prachka />
</template>
