<script>
import AddNews from "@/components/addNews.vue";
export default {
  name: "App",
  components: {
    AddNews,
  },
};
</script>
<template>
  <AddNews />
</template>
